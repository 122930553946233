







































































































import { Component, Vue } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class HandleLogs extends Vue {
  pagerHeader: any = {
    title: "操作日志",
    desc: "所有管理员操作日志",
    breadcrumb: ["系统管理", "操作日志"]
  };
  dialogVisible: boolean = false;
  loading: boolean = false;
  keywords: string = "";
  filter: any = {
    keywords: "",
    page: 1,
    size: 15,
    userType: 1
  };
  tableData: any[] = [];
  dialogData: any = null;

  async search() {
    this.filter.page = 1;
    this.filter.keywords = this.keywords;
    await this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/User/GetAuditLogs", {
        params: this.filter
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch {}

    this.loading = false;
  }

  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }

  async created() {
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
  }

  showDetails(index: number, row: any) {
    this.dialogData = row;
    this.dialogVisible = true;
  }

  get userTypeOptions() {
    return this.getEnum("UserType");
  }
}
